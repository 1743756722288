import React from 'react';

import MailchimpSubscribe from 'react-mailchimp-subscribe'

const SubscribeForm = ({status, message, onValidated}) => {
	let email, tags;
	const submit = () => {
		email &&
		tags &&
		email.value.indexOf("@") > -1 &&
		onValidated({
			EMAIL: email.value
		});
	}

	return (
		<div className="lg:flex-1 max-w-full w-full">
			<h4 className="uppercase text-xs mb-4 text-gray-400 tracking-widest font-sans">Subscribe to the Latest</h4>
			{status === "error" &&
				<div className="text-gold-100 font-serif text-xl" dangerouslySetInnerHTML={{ __html: message }} />
			}
			{status === "success" &&
				<div className="text-gold-500 font-serif text-xl" dangerouslySetInnerHTML={{ __html: message }} />
			}

			{status !== "success" && status !== "error" &&
				<div>
					<input className="w-full px-4 py-3 text-gold-100 font-serif bg-green-500 border-gray-900 placeholder:placeholder:font-serif placeholder:text-gold-100" type="email" ref={node => (email = node)} placeholder="Email Address" />
					<input type="hidden" ref={node => (tags = node)} value="footer-subscribe-form" />
					<div className="flex justify-center mt-8">
						<button onClick={submit} className="border border-gold-500 border-solid rounded-full text-sm py-3 px-6 text-gold-500 uppercase tracking-widest hover:text-green-900 hover:bg-gold-500">
							Subscribe
						</button>
					</div>
				</div>
			}
			
		</div>
	)
}

const MailSubscribe = () => {
	const url = "https://clearlyreformed.us14.list-manage.com/subscribe/post?u=5447deec5cf8a592ec842e38b&amp;id=9bdb271b58&amp;f_id=0038b5e5f0";
	
	return (
		<MailchimpSubscribe 
			url={url} 
			render={({ subscribe, status, message }) => (
				<SubscribeForm 
					status={status}
					message={message}
					onValidated={formData => subscribe(formData)}
				/>
			)}
		/>
	)

}

export default MailSubscribe