import { Link } from 'gatsby'
import React from 'react'


const Button = ( { link, text, style, target, position } ) => {

    var classNames = "flex mb-12";

    if ( position === 'left' ) {
        classNames += " justify-start";
    } else if ( position === 'right' ) {
        classNames += " justify-end";
    } else {
        classNames += " justify-center";
    }

    return (
        <div className={classNames}>
            <Link
                className="border border-gold-500 border-solid rounded-full py-4 px-6 text-green-900 uppercase text-sm tracking-widest hover:text-green-900 hover:bg-gold-500"
                to={link}
                target={target}
            >
                {text}
            </Link>
        </div>
    )
}

export default Button