import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import SearchIcon from '../svgs/SearchIcon';

const HeaderSearch = ( {className } ) => {
	
	const [ mobileActive, setMobileActive ] = useState( 'hidden' );
	
	const toggleSearch = () => {

		let status = mobileActive;

		if ( status == 'hidden' ) {
			status = 'flex';
		} else {
			status = 'hidden';
		}

		setMobileActive( status );
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
	  } = useForm()
	  const onSubmit = data => {
		window.location.href="/resources/?search=" + data.Search
	  }
	
	const searchClass = "absolute w-full top-20 left-0 flex justify-center py-4 bg-gold-200 lg:relative lg:top-auto lg:left-auto lg:bg-transparent lg:flex lg:py-0 items-center lg:justify-end lg:flex-1 lg:w-0 " + mobileActive;

	return (
		<>
			<div onClick={toggleSearch} className="block absolute top-8 right-20 z-40 lg:hidden cursor-pointer print:hidden">
				<SearchIcon />
			</div>
			<div className={searchClass}> 
				<form onSubmit={handleSubmit(onSubmit)} className="relative w-full lg:w-auto px-4">
					<SearchIcon 
						className="absolute top-4 left-8"
					/>
					<input
						placeholder="Search"
						id="search"
						type="search"
						className="pl-10 focus-visible:outline-gold-700 w-full lg:w-auto mx-auto"
						{... register("Search") }
					/>
				</form>
			</div>
		</>
	)
}

export default HeaderSearch