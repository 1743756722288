import React from 'react'

const PodcastIcon = () => {

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1649_1866)">
            <path d="M9.5 0.5H6.5C5.94772 0.5 5.5 0.947715 5.5 1.5V10.5C5.5 11.0523 5.94772 11.5 6.5 11.5H9.5C10.0523 11.5 10.5 11.0523 10.5 10.5V1.5C10.5 0.947715 10.0523 0.5 9.5 0.5Z" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.5 4.5H6.5" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.5 7.5H6.5" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.5 4.5H10.5" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.5 7.5H10.5" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 11.5V15.502" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 11.5V15.502" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.96402 2.96399C2.49958 3.4283 2.13117 3.97955 1.87981 4.58627C1.62846 5.19298 1.49908 5.84327 1.49908 6.49999C1.49908 7.15671 1.62846 7.807 1.87981 8.41371C2.13117 9.02043 2.49958 9.57168 2.96402 10.036" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.036 2.96704C13.9737 3.90472 14.5004 5.17648 14.5004 6.50254C14.5004 7.82861 13.9737 9.10036 13.036 10.038" stroke="#CEA355" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_1649_1866">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default PodcastIcon