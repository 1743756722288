import React from 'react';
import SiteLogo from './SiteLogo';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Navigation from './Navigation';
import HeaderSearch from './HeaderSearch';
import ArrowIcon from '../svgs/ArrowIcon';

const Header = () => {

	const { allWp } = useStaticQuery(graphql`
		{
			allWp {
    			nodes {
					kdySettings {
						settingOptions {
							message
							linkText
							link
						}
					}
				}
			}
		}
	`)

	console.log(allWp)

	return (
		<>
			 <a className="block relative z-10 antialiased bg-green-500 py-2 w-full hover:bg-green-600 text-white text-center cursor-pointer font-sans" href={allWp?.nodes[0]?.kdySettings?.settingOptions?.link} target="_blank">
				{allWp?.nodes[0]?.kdySettings?.settingOptions?.message} <span className="text-gold-700 md:w-auto relative mx-2">{allWp?.nodes[0]?.kdySettings?.settingOptions?.linkText} <span className="inline-block relative top-[2px] -right-1"><ArrowIcon /></span></span>
			</a>
			
			<div className="max-w-7xl mx-auto px-4 sm:px-6 relative z-20">
				<div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
					<div className="flex justify-start lg:w-0 lg:flex-1">
						<Link to={"/"}>
							<span className="sr-only">Clearly Reformed Home Link</span>
							<SiteLogo />
						</Link>
					</div>
					<Navigation />
					<HeaderSearch />
				</div>
			</div>
		</>
	 );
}

export default Header;