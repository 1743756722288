import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby'

const SocialMenu = () => {

	const { wpMenu } = useStaticQuery(graphql`
		{
			wpMenu(locations: { eq: SOCIAL }) {
				name
				menuItems {
					nodes {
						label
						url
						databaseId
						connectedNode {
							node {
								... on WpContentNode {
									uri
								}
							}
						}
					}
				}
			}
		}
	`)
	
	if (!wpMenu?.menuItems?.nodes || wpMenu.menuItems.nodes === 0) return null

	return (
		<div className="flex-1">
			<h4 className="uppercase font-sans text-xs mb-4 text-gray-400 tracking-widest">{wpMenu.name}</h4>
				<ul className="list-none ml-0 mb-0">
					{wpMenu.menuItems.nodes.map((menuItem, i) => {
						return(
							<li key={i} className="py-1 mb-0">
								<a href={menuItem.url} className="font-serif text-white" target="_blank">
									{menuItem.label}
								</a>
							</li>
						)
					})}
				</ul>
		</div>
	)
}

export default SocialMenu