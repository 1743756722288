import React from 'react'

const MenuIcon = ( { className }) => {

    return (
        <svg width="24" height="15" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1" x2="32" y2="1" stroke="#1F2828" strokeWidth="2"/>
            <line y1="19" x2="32" y2="19" stroke="#1F2828" strokeWidth="2"/>
            <line y1="10" x2="32" y2="10" stroke="#1F2828" strokeWidth="2"/>
        </svg>
    )
}

export default MenuIcon