import React from 'react'

const ArrowIcon = () => {

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 8.5H15.5" stroke="#CD8A11" strokeMiterLimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.5 3.5L15.5 8.5L10.5 13.5" stroke="#CD8A11" strokeMiterLimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ArrowIcon