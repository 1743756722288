import React from 'react'

const RSSIcon = () => {

    return (
        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.32143 10C1.04116 10 0 11.1219 0 12.5C0 13.8781 1.04116 15 2.32143 15C3.6017 15 4.64286 13.8781 4.64286 12.5C4.64286 11.1219 3.60112 10 2.32143 10ZM2.32143 14C1.55246 14 0.928571 13.3281 0.928571 12.5C0.928571 11.6719 1.55246 11 2.32143 11C3.0904 11 3.71429 11.6719 3.71429 12.5C3.71429 13.3281 3.0904 14 2.32143 14ZM0.464286 1C0.207652 1 0 1.22375 0 1.5C0 1.77625 0.207652 2 0.464286 2C6.86562 2 12.0714 7.60625 12.0714 14.5C12.0714 14.7764 12.2791 15 12.5357 15C12.7923 15 13 14.7764 13 14.5C13 7.05625 7.37634 1 0.464286 1ZM0.464286 5.5C0.207652 5.5 0 5.725 0 6C0 6.275 0.207652 6.5 0.464286 6.5C4.56161 6.5 7.89286 10.0875 7.89286 14.5C7.89286 14.7764 8.10051 15 8.35714 15C8.61378 15 8.82143 14.7764 8.82143 14.5C8.82143 9.5375 5.07232 5.5 0.464286 5.5Z" fill="#CEA355"/>
        </svg>
    )
}

export default RSSIcon