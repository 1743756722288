import React, { useState } from "react"
import { Link } from "gatsby"
import FooterSitemap from "./FooterSitemap"
import PodcastIcon from "../svgs/PodcastIcon";
import ExternalLink from "../svgs/ExternalLink";
import RSSIcon from "../svgs/RSSIcon";
import ResourceFooterSitemap from "./ResourcesFooterSitemap";

const Footer = ({slug}) => {
	console.log( slug );
	const [ podcastActive, setPodcastActive ] = useState('hidden');
	const [ rssActive, setRssActive ] = useState('hidden');

	const GiveRedirect = () => {
		window.location.href = "https://clearlyreformed.org/give"
	}

	const togglePodcasts = () => {

		let status = podcastActive;

		if ( status == 'hidden' ) {
			status = 'flex';
		} else {
			status = 'hidden';
		}

		setPodcastActive( status );
		setRssActive( 'hidden' );
	}

	const toggleRss = () => {

		let status = rssActive;

		if ( status == 'hidden' ) {
			status = 'flex';
		} else {
			status = 'hidden';
		}

		setRssActive( status );
		setPodcastActive('hidden');
	}

	const podcastClass = 'absolute drop-shadow-sm bg-white p-2 border border-gray-200 rounded-3xl text-left bottom-8 flex flex-col w-48 ' + podcastActive;
	const rssClass = 'absolute drop-shadow-sm bg-white p-2 border border-gray-200 rounded-3xl text-left bottom-8 lg:-right-2 flex flex-col w-48 ' + rssActive;

  	return (
		<footer id="site-footer" role="contentinfo" className="bg-green-900 relative z-10">
				{ slug === 'resources' ?
					<ResourceFooterSitemap />
				:
					<FooterSitemap />
				}

				<div className="container mx-auto px-4 flex flex-col-reverse lg:flex-row border-t border-t-gray-600 py-8 ">
					<p className="font-sans text-white text-sm mb-0 flex-1 mt-4 lg:mt-0">
						© {new Date().getFullYear()}{" "}
						<Link className="text-white underline" to={"/"}>Clearly Reformed</Link>
					</p>
					<div className="subscribe flex-1 flex lg:justify-end gap-4">
						<div className="relative flex-1 lg:flex-initial">
							<button className="flex text-gold-500 text-sm items-center gap-2" onClick={togglePodcasts}>
								<PodcastIcon />
								Subscribe to Podcast
							</button>
							<div className={podcastClass}>
								<a className="flex items-center gap-2 py-2 px-4 w-full rounded-full capitalize text-sm text-black hover:bg-gold-100" href="https://podcasts.apple.com/us/podcast/life-and-books-and-everything/id1526483896" target="_blank">Apple Podcasts <ExternalLink /></a>
								<a className="flex items-center gap-2 py-2 px-4 w-full rounded-full capitalize text-sm text-black hover:bg-gold-100" href="https://open.spotify.com/show/1zfMfz7mHfzsgJZvcKsXTa" target="_blank">Spotify <ExternalLink /></a>
								<a className="flex items-center gap-2 py-2 px-4 w-full rounded-full capitalize text-sm text-black hover:bg-gold-100" href="https://www.stitcher.com/podcast/life-and-books-and-everything" target="_blank">Stitcher <ExternalLink /></a>
							</div>
						</div>
						<div className="relative flex-1 lg:flex-initial">
							<button className="flex text-gold-500 text-sm items-center gap-2" onClick={toggleRss}>
								<RSSIcon />
								Subscribe to RSS
							</button>
							<div className={rssClass}>
								<a className="flex items-center gap-2 py-2 px-4 w-full rounded-full capitalize text-sm text-black hover:bg-gold-100" href="/rss.xml" target="_blank">Articles RSS Feed</a>
								<a className="flex items-center gap-2 py-2 px-4 w-full rounded-full capitalize text-sm text-black hover:bg-gold-100" href="/rss-sermons.xml" target="_blank">Sermons RSS Feed</a>
								<a className="flex items-center gap-2 py-2 px-4 w-full rounded-full capitalize text-sm text-black hover:bg-gold-100" href="/rss-resources.xml" target="_blank">Resources RSS Feed</a>
							</div>
						</div>
						<div className="relative flex-1 lg:flex-initial">
							<div className="flex text-gold-500 text-sm items-center gap-2 hover:cursor-pointer" onClick={ GiveRedirect }>
								Give to Clearly Reformed
							</div>
						</div>
					</div>
				</div>

		</footer>
	)
}

export default Footer
