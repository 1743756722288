import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import SocialMenu from "./SocialMenu"
import MailSubscribe from "./MailSubscribe"

const FooterSitemap = () => {

	const { wpMenu } = useStaticQuery(graphql`
		{
			wpMenu(locations: { eq: FOOTER }) {
				name
				menuItems {
					nodes {
						label
						url
						databaseId
						connectedNode {
							node {
								... on WpContentNode {
									uri
								}
							}
						}
					}
				}
			}
		}
	`)

	if (!wpMenu?.menuItems?.nodes || wpMenu.menuItems.nodes === 0) return null

  return (
		<div className="container mx-auto py-24 px-4 flex flex-wrap print:hidden">
			<div className="flex-1">
				<h4 className="uppercase text-xs mb-4 text-gray-400 tracking-widest font-sans">{wpMenu.name}</h4>
				<ul className="list-none ml-0 mb-0">
					{wpMenu.menuItems.nodes.map((menuItem, i) => {
						return(
							<li key={i} className="py-1 mb-0">
								<Link to={menuItem.url} className="font-serif text-white">
									{menuItem.label}
								</Link>
							</li>
						)
					})}
				</ul>
			</div>

			<SocialMenu />

			<MailSubscribe />

		</div>
  )
}

export default FooterSitemap
