import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"
import HeaderSearch from './HeaderSearch'
import MenuIcon from '../svgs/MenuIcon'


const Navigation = () => {

	const [ mobileActive, setMobileActive ] = useState( 'hidden' );

	const toggleMenu = () => {

		let status = mobileActive;

		if ( status == 'hidden' ) {
			status = 'flex';
		} else {
			status = 'hidden';
		}

		setMobileActive( status );
	}

	const GiveNavRedirect = () => {
		window.location.href = "https://clearlyreformed.org/give"
	}

	const menuClass = "space-x-10 relative z-20 lg:flex " + mobileActive;

	const { wpMenu } = useStaticQuery(graphql`
		{
			wpMenu(locations: { eq: PRIMARY }) {
				name
				menuItems {
					nodes {
						label
						url
						databaseId
						connectedNode {
							node {
								... on WpContentNode {
									uri
								}
							}
						}
					}
				}
			}
		}
	`)

	if (!wpMenu?.menuItems?.nodes || wpMenu.menuItems.nodes === 0) return null
	
	return (
		<div>
			<div onClick={toggleMenu} className="block absolute top-8 right-8 z-40 lg:hidden cursor-pointer print:hidden">
				<MenuIcon />
			</div>
			<nav 
				className={menuClass}
				role="navigation"
			>
				<ul className="flex flex-col fixed left-0 top-0 w-full h-full bg-gold-100 justify-center px-8 lg:px-0 lg:relative lg:flex-row lg:bg-transparent list-none ml-0 mb-0 font-sans">
					{wpMenu.menuItems.nodes.map((menuItem, i) => {
						return(
							<li key={i} className="mb-0">
								<Link to={menuItem.url} className="uppercase my-4 lg:my-0 block lg:text-sm lg:mx-6 tracking-wider text-green-600">
									{menuItem.label}
								</Link>
							</li>
						)
					})}
					<li className="mb-0">
						<div href="https://clearlyreformed.org/give" className="uppercase my-4 lg:my-0 block lg:text-sm lg:mx-6 tracking-wider text-green-600 hover:cursor-pointer" onClick={ GiveNavRedirect }>
							Give
						</div>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export default Navigation